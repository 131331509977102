import clsx from 'clsx'
import {Link} from './link'

export function Text({className, ...props}: React.ComponentPropsWithoutRef<'p'>) {
  return (
    <p
      data-slot="text"
      {...props}
      className={clsx(className, 'text-slate-900 sm:text-md/6 dark:text-slate-400 leading-8')}
    />
  )
}

export function TextLink({className, ...props}: React.ComponentPropsWithoutRef<typeof Link>) {
  return (
    <Link
      {...props}
      className={clsx(
        className,
        'underline underline-offset-4 dark:text-white dark:decoration-white/50 dark:data-[hover]:decoration-white'
      )}
    />
  )
}

export function Strong({className, ...props}: React.ComponentPropsWithoutRef<'strong'>) {
  return <strong {...props} className={clsx(className, 'font-medium text-black dark:text-white')}/>
}

export function Code({className, ...props}: React.ComponentPropsWithoutRef<'code'>) {
  return (
    <code
      {...props}
      className={clsx(
        className,
        'rounded border border-slate-950/10 bg-slate-950/[2.5%] px-0.5 text-sm font-medium text-black sm:text-[0.8125rem] dark:border-white/20 dark:bg-white/5 dark:text-white'
      )}
    />
  )
}
